export const IMAGE = {
    1 : require('../Assets/Image/1.png'),
    2 : require('../Assets/Image/2.png'),
    3 : require('../Assets/Image/3.png'),
    deepworld : require('../Assets/Image/dpworld.png'),
    delhivery : require('../Assets/Image/delhivery.png'),
    scorpion : require('../Assets/Image/scorpion.png'),
    atomberg : require('../Assets/Image/atomberg.png'),
    Siemens : require('../Assets/Image/Siemens.png'),
    fc_logo : require('../Assets/Image/fc_logo.png'),
    vip : require('../Assets/Image/vip.png'),
    Caprihans : require('../Assets/Image/Caprihans.png'),
    lavie : require('../Assets/Image/lavie-logo.avif'),
    ftl : require('../Assets/Image/ftl.png'),
    ptl : require('../Assets/Image/ptl.jpeg'),
    lms : require('../Assets/Image/lms.jpg'),
    pm : require('../Assets/Image/pm.jpeg'),
    pl : require('../Assets/Image/PL.png'),
    xpress : require('../Assets/Image/vxpress-logo.png'),




}